import axios from "axios";

const getMembers = async () => {
    const response = await axios.get("/data/members.json", {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    });
    return response.data;
}

export default getMembers;