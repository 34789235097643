import React, {useEffect, useState} from "react";
import getGamesSortedByDate from "./api/games";
import {Card, CardBody, CardText, CardTitle, Table} from "react-bootstrap";

const fetchNext = (games) => {
    return games.find(g => new Date(g.date) > Date.now());
}

const formatDate = dateString => {
    if (!dateString) {
        return "";
    }
    let d = new Date(dateString);
    return d.getDate() + "/" + (d.getMonth()+1) + "/" + d.getFullYear() + " " + d.getHours() + "u" + d.getMinutes();
}

const formatScore = game => {
    if (game.homeGoals < 0) {
        return "N/A"
    }
    return game.homeGoals + "-" + game.guestGoals;
}

const Games = () => {

    const [state, setState] = useState({
        games: [],
        nextGame: null
    });

    useEffect(() => {
        getGamesSortedByDate().then(data => {
            setState({games: data, nextGame: fetchNext(data) });
        });
    }, []);

    return (

        <div>

            <div>
                <h2>Volgende wedstrijd</h2>
                <Card>
                    <CardBody>
                        <CardTitle>{state.nextGame?.homeTeam} - {state.nextGame?.guestTeam}</CardTitle>
                        <CardText>{formatDate(state.nextGame?.date)}</CardText>
                    </CardBody>

                </Card>
            </div>
            <div>
                <h2>Wedstrijden</h2>
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Wedstrijd</th>
                        <th>Uitslag</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.games.map((game, index) =>
                        <tr key={index}>
                            <td>{formatDate(game.date)}</td>
                            <td>{game.homeTeam} - {game.guestTeam}</td>
                            <td>{formatScore(game)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Games;