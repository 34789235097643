import * as R from "ramda";
import React, {useEffect, useState} from "react";
import getMembers from "./api/members"
import MembersPayed from "./MembersPayed";
import {countNrOfPayedMembers} from "./js/members";

const sortByName = R.sortWith([
    R.ascend(R.prop('idName')),
]);

const sortByGoals = R.sortWith([
    R.descend(R.prop('goals')),
    R.ascend(R.prop('name')),
]);

const sortByPresence = R.sortWith([
    R.descend(R.prop('games')),
    R.ascend(R.prop('name')),
]);

const MembersColumn = (props) => {
    return (
        <th onClick={props.onClick}>{props.title}</th>
    );
}

const MembersRow = (props) => {
    const bgColor = props.payed ? '' : 'red';
    return (
        <tr key={props.index}>
            <td style={{
                backgroundColor: bgColor
            }}>{props.idName}</td>
            <td>{props.goals}</td>
            <td>{props.games}</td>
        </tr>
    );
}

const Members = () =>{

    const [state, setState] = useState({
        members: []
    });
    const [amountMembersPayed, setAmountMembersPayed] = useState(0);
    const [amountMembers, setAmountMembers] = useState(0);

    const sortMembersByName = () => {
        setState(() => ({
            members: sortByName(state.members)
        }));
    }

    const sortMembersByGoals = () => {
        setState(() => ({
            members: sortByGoals(state.members)
        }));
    }

    const sortMembersByPresence = () => {
        setState(() => ({
            members: sortByPresence(state.members)
        }));
    }

    useEffect(() => {
        getMembers().then(data => {
            setState({members: sortByName(data)});
            setAmountMembersPayed(countNrOfPayedMembers(data));
            setAmountMembers(data.length);
        });
    }, []);

    return (
        <div>
            <h1 data-cy="nrOfMembers">Leden: {state.members.length}</h1>
            <MembersPayed payed={amountMembersPayed} total={amountMembers}/>
            <table>
                <thead>
                <tr>
                    <MembersColumn title={"Lid"} onClick={sortMembersByName}/>
                    <MembersColumn title={"Goals"} onClick={sortMembersByGoals}/>
                    <MembersColumn title={"Matchen"} onClick={sortMembersByPresence} />
                </tr>
                </thead>
                <tbody data-cy="listOfMembers">
                {state.members.map((player, index) =>
                    <MembersRow key={index} index={index} idName={player.idName} goals={player.goals} games={player.games} payed={player.payed} />
                )}
                </tbody>
            </table>
        </div>
    );
}

export default Members