import * as R from "ramda";

const diffByDate = (aGameDay, anotherGameDay) => {
    return new Date(aGameDay.date).getTime() - new Date(anotherGameDay.date).getTime();
}
export const getLatestGameDay = (season) => {
    const sortedGameDays = R.sort(diffByDate, season.gameDays);
    const latestIndex = sortedGameDays.length - 1;
    const latestGameDay = sortedGameDays[latestIndex];
    return {
        index: latestIndex,
        gameDay: latestGameDay
    }
}

export const getGameDayFirstBefore = (currentIndex, season) => {
    const sortedGameDays = R.sort(diffByDate, season.gameDays);
    const index = currentIndex === 0 ? currentIndex : currentIndex - 1;
    const gameDay = sortedGameDays[index];
    return {
        index: index,
        gameDay: gameDay
    }
}

export const getGameDayFirstAfter = (currentIndex, season) => {
    const sortedGameDays = R.sort(diffByDate, season.gameDays);
    const index = currentIndex === (season.gameDays.length - 1) ? currentIndex : currentIndex + 1;
    const gameDay = sortedGameDays[index];
    return {
        index: index,
        gameDay: gameDay
    }
}