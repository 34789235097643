import axios from "axios";

const fetchSeason = async () => {
    const response = await axios.get("/data/season.json", {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    });
    return response.data;
}

export default fetchSeason;