import React, {useEffect, useState} from "react";
import {Button, Stack, Table} from "react-bootstrap";
import fetchSeason from "./api/ranking";
import {getGameDayFirstAfter, getGameDayFirstBefore, getLatestGameDay} from "./js/ranking";

const Ranking = () => {

    const [season, setSeason] = useState([]);

    const [gameDay, setGameDay] = useState([]);

    const [gameDayIndex, setGameDayIndex] = useState(0);

    useEffect(() => {
        fetchSeason().then(data => {
            setSeason(data);
            const {index, gameDay} = getLatestGameDay(data);
            setGameDayIndex(index);
            setGameDay(gameDay);
        });
    }, []);

    const scrollNextGameDay = () => {
        const {index, gameDay} = getGameDayFirstAfter(gameDayIndex, season);
        setGameDayIndex(index);
        setGameDay(gameDay)
    }

    const scrollPreviousGameDay = () => {
        const {index, gameDay} = getGameDayFirstBefore(gameDayIndex, season);
        setGameDayIndex(index);
        setGameDay(gameDay)
    }

    return (
        <div>
            <h2>{'Rangschikking'}</h2>
            <Stack direction="horizontal" gap={3}>
                <div className="p-2"><Button className={"btnGameDayLeft"} variant="outline-primary" onClick={scrollNextGameDay}>{'<<'}</Button></div>
                <div className="p-2"><em data-cy="currentGameDayDate">{gameDay.date}</em></div>
                <div className="p-2"><Button className={"btnGameDayRight"} variant="outline-primary" onClick={scrollPreviousGameDay}>{'>>'}</Button></div>
            </Stack>
            <Table striped size="sm">
                <thead>
                <tr>
                    <th>Team</th>
                    <th>Ptn</th>
                    <th>M</th>
                    <th>M+</th>
                    <th>M-</th>
                    <th>M=</th>
                    <th>D+</th>
                    <th>D-</th>
                </tr>
                </thead>
                <tbody>
                    {gameDay.ranking?.ranking.map((teamRanking, index) =>
                        <tr key={index}>
                            <td>{teamRanking.team}</td>
                            <td>{teamRanking.points}</td>
                            <td>{teamRanking.wins + teamRanking.loss + teamRanking.draw}</td>
                            <td>{teamRanking.wins}</td>
                            <td>{teamRanking.loss}</td>
                            <td>{teamRanking.draw}</td>
                            <td>{teamRanking.scored}</td>
                            <td>{teamRanking.conceived}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default Ranking